import React, { ReactElement, useCallback, useEffect, useState } from 'react'
import { graphql } from 'gatsby'
import Layout from '../../layouts/Layout/Layout'
import './ContactUs.scss'
import { RecentArticleTypes } from '../../layouts/ArticleLayout/ArticleLayout'
import CONTACT_US_NAVIGATION from '../../data/contact-us-navigation.json'
import Seo from '../../components/Seo/Seo'
import {
  addFormValidation,
  setIsIframe,
} from '../../components/Validators/InputValidations'
import { toKebabCase } from '../../services/caseTransform'
import loadable from '@loadable/component'
import ContactUsLayout from '../../layouts/ContactUsLayout/ContactUsLayout'
import SalesForceForm from '../../components/SalesForceForm/SalesForceForm'
import ContactUsFormSF from '../../components/ContactUsFormSF/ContactUsFormSF'
import ContactUsFormSFSecond from '../../components/ContactUsFormSFSecond/ContactUsFormSFSecond'

const ArticleLayout = loadable(
  () => import('../../layouts/ArticleLayout/ArticleLayout'),
)

const ThirdPartyForm = loadable(
  () => import('../../components/ThirdPartyForm/ThirdPartyForm'),
)

const PopupModal = loadable(
  () => import('../../components/PopupModal/PopupModal'),
)

type ContactUsPropTypes = {
  data: {
    recentArticles: {
      nodes: RecentArticleTypes[]
    }
    allShowroom: {
      nodes: {
        showRoomTitle: string
      }[]
    }
    homePageSeo: {
      seoContactTitle: string
      seoContactDescription: string
    }
  }
}

const ContactUs = ({
  data: {
    recentArticles: { nodes: recentArticles },
    allShowroom: { nodes: allShowroom },
    homePageSeo: { seoContactTitle, seoContactDescription },
  },
}: ContactUsPropTypes): ReactElement => {
  const [isSuccessShown, setIsSuccessShown] = useState<boolean>(false)
  const [displayForm, setDisplayForm] = useState(true)

  useEffect(() => {
    if (!displayForm) {
      setDisplayForm(true)
    }
  }, [displayForm])

  const handleSubmit = useCallback(() => {
    setIsSuccessShown(true)
  }, [])

  const handleFormReady = useCallback(() => {
    setIsIframe(true)
    addFormValidation('contact-us-form', [
      {
        elementId: 'firstname-50eb0e49-8831-419c-bfe5-501f36d6a5a8',
        validationType: 'alphaOnly',
      },
      {
        elementId: 'lastname-50eb0e49-8831-419c-bfe5-501f36d6a5a8',
        validationType: 'alphaOnly',
      },
      {
        elementId: 'phone-50eb0e49-8831-419c-bfe5-501f36d6a5a8',
        validationType: 'numericOnly',
      },
      {
        elementId: 'address-50eb0e49-8831-419c-bfe5-501f36d6a5a8',
        validationType: 'alphaNumericWithSpecialCharacters',
      },
      {
        elementId: 'message-50eb0e49-8831-419c-bfe5-501f36d6a5a8',
        validationType: 'alphaNumericWithSpecialCharacters',
      },
      {
        elementId: 'mobilephone-50eb0e49-8831-419c-bfe5-501f36d6a5a8',
        validationType: 'numericOnly',
      },
    ])
  }, [])

  const handleSuccessClose = useCallback(() => {
    setIsSuccessShown(false)
    setDisplayForm(false)
  }, [])

  const initialShowroom = allShowroom[0]
  const showroomUrl = toKebabCase(initialShowroom.showRoomTitle)

  const contactUsNavigation = CONTACT_US_NAVIGATION.map((item) => {
    return {
      url: item?.url,
      label: item.label,
      items: item.items?.map((val) => {
        return {
          url:
            val.url === 'contact-us/showroom'
              ? `contact-us/showroom/${showroomUrl}`
              : val.url,
          label: val.label,
        }
      }),
    }
  })

  return (
    <Layout>
      <Seo
        slug="contact-us"
        title={
          seoContactTitle !== 'nc'
            ? seoContactTitle
            : 'Contact Us | Sales Inquiry'
        }
        ogMetaData={{
          title:
            seoContactTitle !== 'nc'
              ? seoContactTitle
              : 'Contact Us | Sales Inquiry',
          description:
            seoContactDescription !== 'nc'
              ? seoContactDescription
              : 'Already have an Avida project in mind? Provide the necessary information below for a simple and seamless homebuying experience.',
          image: '',
        }}
        jsonData={{
          url: `${process.env.GATSBY_SITE_URL}/contact-us/`,
          keywords:
            'properties, condo, philippines, house and lot, avidaland, avida, apartment, contact us, sales inquiry',
        }}
      />
      <ContactUsLayout className="contact-us">
        <ArticleLayout
          title="Contact Us"
          asideNavItems={contactUsNavigation}
          asideArticleTitle="News & Events"
          breadcrumbs={[
            {
              label: 'Contact Us',
            },
          ]}
          recentArticles={recentArticles}
        >
          {seoContactTitle !== 'nc' && (
            <h1 style={{ display: 'none' }}>{seoContactTitle}</h1>
          )}
          <h2 className="contact-us-title">Sales Inquiry </h2>
          <p className="contact-us-form-title">
            Already have an Avida project in mind?
          </p>
          <p className="contact-us-form-title">
            Provide the necessary information below for a simple and seamless
            homebuying experience.
          </p>

          {/* <h4>Axios</h4>
          <ContactUsFormSF />
          <h4>Plain</h4>
          <ContactUsFormSFSecond /> */}

          {/* <SalesForceForm /> */}

          {!!displayForm && (
            <ThirdPartyForm
              id="contact-us-form"
              portalId="4090950"
              formId="50eb0e49-8831-419c-bfe5-501f36d6a5a8"
              onFormSubmit={handleSubmit}
              onFormReady={handleFormReady}
            />
          )}
        </ArticleLayout>
      </ContactUsLayout>
      <PopupModal
        isShowing={isSuccessShown}
        onClose={handleSuccessClose}
        header="Thank you for your inquiry"
        body="Please expect our Customer Care representative to get in touch with you as soon as possible."
        contactList={[
          'Sales Hotline: (+632) 848-5200',
          'Office Trunk Line: (+632) 988-2111',
        ]}
      />
    </Layout>
  )
}

export default ContactUs

export const pageQuery = graphql`
  query {
    recentArticles: allNewsAndEvents(limit: 3) {
      nodes {
        ...NewsAndEventsCardFields
      }
    }
    allShowroom(filter: { type: { eq: "" } }) {
      nodes {
        showRoomTitle
      }
    }
    homePageSeo {
      seoContactTitle
      seoContactDescription
    }
  }
`
